<template>
    <button class="magic-button" @click="do_click" v-bind:style="{ backgroundColor: back_color }">
        {{ btn_text }}
    </button>
</template>
<style>
/* Material style */
.magic-button {
    border: none;
    cursor: pointer;
    color: white;
    width: 100%;
    border-radius: 2px;
    height: 41px;
    font-size: 18px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    background: rgb(62, 153, 32);
    margin-top: 20px;
}

/* Ripple magic */
.magic-button {
    position: relative;
    overflow: hidden;
}

.magic-button:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 5px;
    background: rgba(255, 255, 255, 0.5);
    opacity: 0;
    border-radius: 100%;
    transform: scale(1, 1) translate(-50%);
    transform-origin: 50% 50%;
}

@keyframes ripple {
    0% {
        transform: scale(0, 0);
        opacity: 1;
    }
    20% {
        transform: scale(25, 25);
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: scale(50, 50);
    }
}

.magic-button:focus:not(:active)::after {
    animation: ripple 1s ease-out;
}
</style>
<script>
import Vue from 'vue';

export default {
    name: 'MagicButton',
    props: ['handleClick', 'btn_text', 'back_color'],
    data() {
        return {};
    },
    mounted() {},
    methods: {
        do_click: function () {
            this.handleClick();
        }
    }
};
</script>
