<template>
    <div class="container" v-bind:style="back_style">
        <img class="init-userinfo-avatar" v-if="slogo" :src="slogo" background-size="cover" />
        <div v-else style="height: 87px; width: 84px"></div>
        <div>
            <h3>
                <span v-if="name">{{ name }} ,</span>
                {{ $t('welcome.welcome') }}
            </h3>
            <!-- <MagicButton v-bind:handleClick="do_join" btn_text="start pre-order" back_color="rgb(62, 153, 32)"></MagicButton> -->
            <button style="border-radius: 5px; height: 60px; font-size: 20px" class="magic-button" @click="do_join">
                {{ $t('preorder.join_preview') }}
            </button>
            <input v-if="false" style="border-radius: 5px" type="text" v-model="pre_tid_text" readonly="readonly" />
            <input
                @keypress="handle_press"
                @focus="toggle_tip('focus')"
                @blur="toggle_tip('blur')"
                style="border-radius: 5px"
                type="email"
                :placeholder="$t('preorder.receive_email')"
                v-model="uname"
            />
            <div style="width: 100%; margin-top: 7px; text-align: center; color: #bbb; font-size: 13px">
                id: {{ pre_tid }}
            </div>
        </div>
        <div v-if="show_power" style="position: absolute; bottom: 0; left: 0; width: 100%; z-index: 10; color: #000">
            <h4>powered by minitable</h4>
        </div>
    </div>
</template>
<style scoped>
.container {
    padding: 80px 60px 0 60px;
    text-align: center;
}
h3 {
    color: #ffba69;
    /* letter-spacing: 25px; */
    padding-left: 9px;
}
.container input {
    width: 99%;
    color: #333333;
    height: 35px;
    border: 1px solid #000;
    /* border: 1px solid #ccc; */
    margin-top: 20px;
    font-size: 16px;
}
.init-userinfo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.init-userinfo-avatar {
    width: 64px;
    height: 64px;
    margin: 10px;
}
</style>

<script>
import '../assets/style/mine.css';
import Vue from 'vue';
import { Button } from 'mint-ui';
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import MagicButton from '../components/MagicButton';

Vue.component(Button.name, Button);
Vue.component(MagicButton.name, MagicButton);

export default {
    name: 'landing_pre',
    data() {
        return {
            pre_tid: this.$route.query.tid || '',
            pre_tid_text: 'Pre-order ID is ' + this.$route.query.tid,
            name: this.$route.query.name || '',
            uname: '',
            show_power: 1
        };
    },
    // beforeCreate() {
    //     if (this.$route.query.sid != '' && this.$route.query.sid != window.localStorage.getItem('client_sid')) {
    //         return;
    //     }

    //     if (this.$store.state.app.shared_cart_id !== '') {
    //         this.$router.replace({ path: '/menu' });
    //     }
    // },
    created() {
        if (this.$route.query.sid != '' && this.$route.query.sid != window.localStorage.getItem('client_sid')) {
            this.initData({
                sid: this.$route.query.sid,
                pre_tid: this.$route.query.tid,
                router: this.$router
            });
            return;
        }

        if (this.$route.query.tid != '' && this.$route.query.tid != window.localStorage.getItem('shared_tid')) {
            this.initData({
                sid: this.$route.query.sid,
                pre_tid: this.$route.query.tid,
                router: this.$router
            });
            return;
        }

        if (this.$store.state.app.shared_cart_id !== '') {
            this.$router.replace({ path: '/menu' });
            return;
        }
    },
    computed: {
        back_style() {
            if (this.spic === '') {
                return {};
            }

            return {
                backgroundImage: 'url(' + this.spic + ')',
                backgroundRepeat: 'no-repeat',
                height: '100%',
                backgroundSize: 'cover'
            };

            return (
                'background-image: url(' +
                this.spic +
                ');background-repeat: no-repeat;height: 100%;background-size: cover;'
            );
        },
        ...mapGetters({
            language: 'app/get_language',
            spic: 'app/get_store_pic',
            slogo: 'app/get_store_slogo',
            phone_num: 'app/get_phone_num'
        })
    },
    methods: {
        ...mapActions({
            initData: 'landingpre/initData',
            joinTable: 'app/joinTable'
        }),
        do_join() {
            this.joinTable({
                sid: this.$route.query.sid,
                uid: this.$store.state.app.uid,
                tid: this.pre_tid,
                tname: '',
                router: this.$router,
                pswd: '',
                uname: this.uname,
                type: 'preorder',
                phone_num: this.phone_num
            });
        },
        toggle_tip(type) {
            this.show_power = type == 'blur' ? 1 : 0;
        },
        handle_press(e) {
            if (e.keyCode == 13) {
                this.joinTable({
                    sid: this.$route.query.sid,
                    uid: this.$store.state.app.uid,
                    tid: this.pre_tid,
                    tname: '',
                    router: this.$router,
                    pswd: '',
                    uname: this.uname,
                    type: 'preorder',
                    phone_num: this.phone_num
                });
            }
        }
    }
};
</script>
